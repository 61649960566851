<template>
  <vx-card :title="this.pageTitle">
    <div class="vx-row">
      <div class="vx-col w-full">
        <vs-table max-items="10" pagination search stripe :data="options[this.$route.params.institution_id]" :hoverFlat="true">
          <template slot="thead">
            <vs-th sort-key="pageName">Página</vs-th>
            <vs-th>Acciones</vs-th>
          </template>

          <template slot-scope="{data}">
            <tbody>
            <vs-tr :data="record" :key="index" v-for="(record, index) in data">
              <vs-td><p class="font-medium">{{ record.pageName }}</p></vs-td>
              <vs-td>
                <div class="flex">
                  <vs-button
                    size="small" color="success" type="border" icon-pack="feather" :icon="record.buttonSettings.icon"
                    @click="goToOption(record)"
                  >
                    {{ record.buttonSettings.text }}
                  </vs-button>
                </div>
              </vs-td>
            </vs-tr>
            </tbody>
          </template>
        </vs-table>
      </div>
    </div>
  </vx-card>
</template>

<script>
  export default {
    data() {
      return {
        pageTitle: "Páginas Estáticas",
        options: {
          bank: [
            {
              pageName: "Acerca de nosotros",
              buttonSettings: {
                text: "Configurar",
                icon: "icon-settings",
              },
              routeSettings: {
                name: "single-category-static-pages-list",
                params: {
                  institution_id: "bank",
                  category: "about_us"
                }
              }
            },
            {
              pageName: "Banca por Internet",
              buttonSettings: {
                text: "Configurar",
                icon: "icon-settings",
              },
              routeSettings: {
                name: "single-category-static-pages-list",
                params: {
                  institution_id: "bank",
                  category: "internet_banking"
                }
              }
            },
            {
              pageName: "FATCA",
              buttonSettings: {
                text: "Configurar",
                icon: "icon-settings",
              },
              routeSettings: {
                name: "single-category-static-pages-list",
                params: {
                  institution_id: "bank",
                  category: "fatca_law"
                }
              }
            },
            {
              pageName: "Ganadores de Sorteos",
              buttonSettings: {
                text: "Configurar",
                icon: "icon-settings",
              },
              routeSettings: {
                name: "single-category-static-pages-list",
                params: {
                  institution_id: "bank",
                  category: "raffle_winners"
                }
              }
            },
            {
              pageName: "Gobierno Corporativo",
              buttonSettings: {
                text: "Configurar",
                icon: "icon-settings",
              },
              routeSettings: {
                name: "single-category-static-pages-list",
                params: {
                  institution_id: "bank",
                  category: "corporate_governance"
                }
              }
            },
            {
              pageName: "Landings",
              buttonSettings: {
                text: "Configurar",
                icon: "icon-settings",
              },
              routeSettings: {
                name: "single-category-static-pages-list",
                params: {
                  institution_id: "bank",
                  category: "landings"
                }
              }
            },
            {
              pageName: "Artículos Promocionales",
              buttonSettings: {
                text: "Configurar",
                icon: "icon-settings",
              },
              routeSettings: {
                name: "single-category-static-pages-list",
                params: {
                  institution_id: "bank",
                  category: "promotional_items"
                }
              }
            },
            {
              pageName: "Política de privacidad y seguridad",
              buttonSettings: {
                text: "Configurar",
                icon: "icon-settings",
              },
              routeSettings: {
                name: "single-category-static-pages-list",
                params: {
                  institution_id: "bank",
                  category: "privacy_and_security_policy"
                }
              }
            },
            {
              pageName: "Reclamos o Consultas",
              buttonSettings: {
                text: "Configurar",
                icon: "icon-settings",
              },
              routeSettings: {
                name: "single-category-static-pages-list",
                params: {
                  institution_id: "bank",
                  category: "complaints_or_queries"
                }
              }
            },
            {
              pageName: "Responsabilidad social empresarial",
              buttonSettings: {
                text: "Configurar",
                icon: "icon-settings",
              },
              routeSettings: {
                name: "single-category-static-pages-list",
                params: {
                  institution_id: "bank",
                  category: "corporate_social_responsibility"
                }
              }
            },
            {
              pageName: "Servicios Digitales",
              buttonSettings: {
                text: "Configurar",
                icon: "icon-settings",
              },
              routeSettings: {
                name: "single-category-static-pages-list",
                params: {
                  institution_id: "bank",
                  category: "digital_services"
                }
              }
            },
            {
              pageName: "Solicitud de productos",
              buttonSettings: {
                text: "Configurar",
                icon: "icon-settings",
              },
              routeSettings: {
                name: "single-category-static-pages-list",
                params: {
                  institution_id: "bank",
                  category: "product_request"
                }
              }
            },
            {
              pageName: "Teléfono Rojo",
              buttonSettings: {
                text: "Configurar",
                icon: "icon-settings",
              },
              routeSettings: {
                name: "single-category-static-pages-list",
                params: {
                  institution_id: "bank",
                  category: "call_center"
                }
              }
            },
            {
              pageName: "Términos y Condiciones",
              buttonSettings: {
                text: "Configurar",
                icon: "icon-settings",
              },
              routeSettings: {
                name: "single-category-static-pages-list",
                params: {
                  institution_id: "bank",
                  category: "terms_and_conditions"
                }
              }
            },
            {
              pageName: "Usuario financiero",
              buttonSettings: {
                text: "Configurar",
                icon: "icon-settings",
              },
              routeSettings: {
                name: "single-category-static-pages-list",
                params: {
                  institution_id: "bank",
                  category: "financial_user"
                }
              }
            },
            {
              pageName: "Educación financiera",
              buttonSettings: {
                text: "Configurar",
                icon: "icon-settings",
              },
              routeSettings: {
                name: "single-category-static-pages-list",
                params: {
                  institution_id: "bank",
                  category: "financial_education",
                },
              },
            },
            {
              pageName: "Calculadoras",
              buttonSettings: {
                text: "Configurar",
                icon: "icon-settings",
              },
              routeSettings: {
                name: "single-category-static-pages-list",
                params: {
                  institution_id: "bank",
                  category: "calculators"
                }
              }
            },
          ],
          insurances: [
            {
              pageName: "Nuestra historia",
              buttonSettings: {
                text: "Configurar",
                icon: "icon-settings",
              },
              routeSettings: {
                name: "single-category-static-pages-list",
                params: {
                  institution_id: "insurances",
                  category: "our_history"
                }
              }
            },
            {
              pageName: "Proceso de Reclamos",
              buttonSettings: {
                text: "Configurar",
                icon: "icon-settings",
              },
              routeSettings: {
                name: "single-category-static-pages-list",
                params: {
                  institution_id: "insurances",
                  category: "claims_process"
                }
              }
            },
            {
              pageName: "Reclamos o Consultas",
              buttonSettings: {
                text: "Configurar",
                icon: "icon-settings",
              },
              routeSettings: {
                name: "single-category-static-pages-list",
                params: {
                  institution_id: "insurances",
                  category: "complaints_or_queries"
                }
              }
            },
            {
              pageName: "Usuario financiero",
              buttonSettings: {
                text: "Configurar",
                icon: "icon-settings",
              },
              routeSettings: {
                name: "single-category-static-pages-list",
                params: {
                  institution_id: "insurances",
                  category: "financial_user"
                }
              }
            },
            {
              pageName: "Landings",
              buttonSettings: {
                text: "Configurar",
                icon: "icon-settings",
              },
              routeSettings: {
                name: "single-category-static-pages-list",
                params: {
                  institution_id: "insurances",
                  category: "landings"
                }
              }
            },
          ],
          companies: [
            {
              pageName: "Acerca de nosotros",
              buttonSettings: {
                text: "Configurar",
                icon: "icon-settings",
              },
              routeSettings: {
                name: "single-category-static-pages-list",
                params: {
                  institution_id: "companies",
                  category: "about_us",
                },
              },
            },
            {
              pageName: "Banca por Internet",
              buttonSettings: {
                text: "Configurar",
                icon: "icon-settings",
              },
              routeSettings: {
                name: "single-category-static-pages-list",
                params: {
                  institution_id: "companies",
                  category: "internet_banking",
                },
              },
            },
            {
              pageName: "Landings",
              buttonSettings: {
                text: "Configurar",
                icon: "icon-settings",
              },
              routeSettings: {
                name: "single-category-static-pages-list",
                params: {
                  institution_id: "companies",
                  category: "landings",
                },
              },
            },
            {
              pageName: "Servicios Digitales",
              buttonSettings: {
                text: "Configurar",
                icon: "icon-settings",
              },
              routeSettings: {
                name: "single-category-static-pages-list",
                params: {
                  institution_id: "companies",
                  category: "digital_services",
                },
              },
            },
            {
              pageName: "Comercios Afiliados",
              buttonSettings: {
                text: "Configurar",
                icon: "icon-settings",
              },
              routeSettings: {
                name: "single-category-static-pages-list",
                params: {
                  institution_id: "companies",
                  category: "affiliated_businesses"
                }
              }
            },
            {
              pageName: "Educación financiera",
              buttonSettings: {
                text: "Configurar",
                icon: "icon-settings",
              },
              routeSettings: {
                name: "single-category-static-pages-list",
                params: {
                  institution_id: "companies",
                  category: "financial_education",
                },
              },
            },
            {
              pageName: "Usuario financiero",
              buttonSettings: {
                text: "Configurar",
                icon: "icon-settings",
              },
              routeSettings: {
                name: "single-category-static-pages-list",
                params: {
                  institution_id: "companies",
                  category: "financial_user"
                }
              }
            },
            {
              pageName: "Gobierno Corporativo",
              buttonSettings: {
                text: "Configurar",
                icon: "icon-settings",
              },
              routeSettings: {
                name: "single-category-static-pages-list",
                params: {
                  institution_id: "companies",
                  category: "corporate_governance"
                }
              }
            },
          ],
        }
      };
    },

    methods: {
      goToOption(option) {
        this.$router.push({name: option.routeSettings.name, params: option.routeSettings.params});
      }
    },
  };
</script>

<style scoped>
  .vs-table--tbody-table .tr-values.selected {
    cursor: default;
  }
</style>
